import React from 'react'
import Image from 'next/image'

import s from './ourPartners.module.scss'
import Title from '../Title'

const BRANDS = ['accredited', 'wpa', 'pet', 'swiftest']

const OurPartners = () => {
  return (
    <div className={s.partner}>
      <Title variant="h2">Our Partners Include</Title>

      <section className={s.brands}>
        {BRANDS.map((elem, i) => (
          <div className={s.image} key={i}>
            <Image
              width={200}
              height={72}
              src={`/truspilot/${elem}.svg`}
              alt={`${elem} brand `}
            />
          </div>
        ))}
      </section>
    </div>
  )
}

export default OurPartners
