import React, { useState, useRef } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import Link from 'next/link'
import dynamic from 'next/dynamic'
import { Swiper, SwiperSlide } from 'swiper/react'
import cn from 'classnames'
// Components
import PuppyCard from '@/components/newPuppyCard/puppyCard'
// Utils
import { GET_CUSTOMER_FAVOURITES, UPDATE_FAVOURITE } from '@/graphql/index'
import styles from './picFromMix.module.scss'
import { getToken, getUserData } from '@/utils/tokenStorage'
import { isFavourite } from '../search/lib/utils'

const SignupModal = dynamic(() => import('@/components/signupModal'))

const PicCardMix = ({ pickedPuppies }) => {
  const [userData, setUserData] = useState(getUserData(getToken()))
  const [showSignup, setShowSignup] = useState(false)
  const [selectedDog, setSelectedDog] = useState(null)

  /* Swiper Handlers   */
  const ref = useRef(null)

  const [isBeginning, setIsBeginning] = useState(true)
  const [isEnd, setIsEnd] = useState(false)
  const [limit, setLimit] = useState(5)

  const goNext = async () => {
    if (ref.current !== null && ref.current.swiper !== null) {
      await ref.current.swiper.slideNext()
      setIsBeginning(ref.current?.swiper?.isBeginning)
      setIsEnd(ref.current?.swiper?.isEnd)
    }
  }

  const goPrev = async () => {
    if (ref.current !== null && ref.current.swiper !== null) {
      await ref.current.swiper.slidePrev()
      setIsBeginning(ref.current?.swiper?.isBeginning)
      setIsEnd(ref.current?.swiper?.isEnd)
    }
  }

  const { data: favouriteData } = useQuery(GET_CUSTOMER_FAVOURITES, {
    skip: !userData,
  })

  const [updateFavourite] = useMutation(UPDATE_FAVOURITE, {
    refetchQueries: [{ query: GET_CUSTOMER_FAVOURITES }],
    onError: (error) => {
      setShowSignup(true)
      console.error('updateFavourite', error)
    },
  })

  const handleUpdateFavourite = (id) => {
    if (userData) {
      updateFavourite({ variables: { dog: id } })
    } else {
      setShowSignup(true)
      setSelectedDog(id)
    }
  }

  return (
    <>
      {showSignup && (
        <SignupModal
          isOpen={showSignup}
          close={() => setShowSignup(false)}
          success={() => {
            setShowSignup(false)
            setUserData(getUserData(getToken()))
            updateFavourite({
              variables: { dog: selectedDog },
            })
          }}
        />
      )}
      <div className={styles.section}>
        <div className={styles.header}>
          <h2>Our Available Puppies</h2>
          <div className={styles.swiperNav}>
            <Link href="/search"> View All </Link>
            <button
              className={cn({
                [styles.swiperButton]: true,
                [styles.swiperPrev]: !isBeginning,
                [styles.disabledPrev]: isBeginning,
              })}
              onClick={goPrev}
            />
            <button
              className={cn({
                [styles.swiperButton]: true,
                [styles.swiperNext]: !isEnd,
                [styles.disabledNext]: isEnd,
              })}
              onClick={() => {
                goNext()
                if (limit < pickedPuppies.length) {
                  setLimit(limit + 1)
                }
              }}
            />
          </div>
        </div>
        <div className={styles.cardWrapper}>
          <Swiper
            speed={1000}
            initialSlide={0}
            grabCursor
            breakpoints={{
              1020: {
                slidesPerView: 4,
                spaceBetween: 20,
              },
              860: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              540: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              360: {
                slidesPerView: 'auto',
                spaceBetween: 10,
              },
              300: {
                slidesPerView: 'auto',
                spaceBetween: 10,
              },
              290: {
                slidesPerView: 'auto',
                spaceBetween: 10,
              },
            }}
            onSlideChange={() => {
              if (limit < pickedPuppies.length) {
                setLimit(limit + 1)
              }
            }}
            ref={ref}
            preventClicksPropagation={false}
            noSwipingClass="swiper-no-swiping"
          >
            {(pickedPuppies || [])?.slice(0, limit).map((pair, i) => (
              <SwiperSlide key={i}>
                <div className={styles.pair}>
                  <PuppyCard
                    dog={pair[0]}
                    isFavourite={isFavourite(
                      favouriteData?.customer?.favourites || [],
                      pair[0]?.id,
                    )}
                    updateFavourite={() => handleUpdateFavourite(pair[0]?.id)}
                  />

                  <PuppyCard
                    dog={pair[1]}
                    isFavourite={isFavourite(
                      favouriteData?.customer?.favourites || [],
                      pair[1]?.id,
                    )}
                    updateFavourite={() => handleUpdateFavourite(pair[1]?.id)}
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </>
  )
}

export default PicCardMix
