import React from 'react'
import Head from 'next/head'

type BasicHeadSeoProps = {
  title: string
  description: string
  url: string
  image?: string
  content?: string
}

const BasicHeadSeo = ({
  title,
  description,
  url,
  image,
  content,
}: BasicHeadSeoProps) => {
  return (
    <Head>
      {/*  standard tags  */}
      <title>{title}</title>
      <meta name="description" content={description} />
      <link rel="canonical" href={url} />
      <meta charSet="UTF-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="robots" content={content} />
      <meta name="keywords" content="Puppies for Sale | Dogs for Sale" />
      <meta name="p:domain_verify" content="d71dab502a7403e72865596296f1236f" />
      <meta
        name="facebook-domain-verification"
        content="s4bairf9wi49tq06sw832zcct7zf88"
      />
      <meta name="theme-color" content="#ffffff" />
      {/*  Facebook tags  */}
      <meta name="og:title" content={title} key="title" />
      <meta name="og:type" content="website" key="type" />
      <meta name="og:url" content={url} key="url" />
      <meta name="og:description" content={description} />
      <meta name="og:locale" content="en_US" key="locale" />
      <meta property="og:image" content={image} key="image" />
      <meta name="og:image:url" content={image} key="imageUrl" />
      <meta name="og:image:secure_url" content={url} key="imageSecureUrl" />
      <meta name="og:image:width" content="1600" key="imageWidth" />
      <meta name="og:image:height" content="906" key="imageHeight" />
      <meta property="og:image:alt" content="Search image" key="Mawoo Social" />
      <meta name="og:image:type" content="image/jpeg" key="imageType" />
      {/*  twitter tags  */}
      <meta
        name="twitter:card"
        content="summary_large_image"
        key="twitterCard"
      />
      <meta name="twitter:title" content={title} key="twitterTitle" />
      <meta
        name="twitter:description"
        content={description}
        key="twitterDescription"
      />
      <meta name="twitter:image" content={image} key="twitterImage" />
    </Head>
  )
}

export default BasicHeadSeo
