import { useRef } from 'react'
import Image from 'next/image'
import Link from 'next/link'
import cn from 'classnames'
import { Swiper, SwiperSlide } from 'swiper/react'
import Title from '../Title'
import TextBody from '../TextBody/TextBody'
import Button from '../newButton/Button'

import styles from './CustomerReview.module.scss'

import REVIEWS from './lib/reviews.json'

export default function CustomerReview() {
  const ref = useRef(null)

  const goNext = () => {
    if (ref.current !== null && ref.current.swiper !== null) {
      ref.current.swiper.slideNext()
    }
  }

  const goPrev = () => {
    if (ref.current !== null && ref.current.swiper !== null) {
      ref.current.swiper.slidePrev()
    }
  }

  return (
    <div className={styles.reviews}>
      <div className={styles.content}>
        <div className={styles.header}>
          <Title variant="h2">Hear From Our Happy Customers</Title>

          <TextBody size="m">
            Heartwarming stories from our happy customers!
          </TextBody>
        </div>

        <a
          className={styles.rating}
          aria-label="Read more about trustpilot"
          href="https://www.trustpilot.com/review/www.mawoopets.com?utm_medium=trustbox&utm_source=Carousel"
        >
          <Title variant="h3">Excellent</Title>

          <div
            className={cn(styles.image, styles.stars)}
            role="img"
            aria-label="Stars Trustpilot"
          />

          <TextBody size="s">
            <b>4.7</b> out of 5 on
          </TextBody>

          <div
            className={cn(styles.image, styles.logo)}
            role="img"
            aria-label="Brand Trustpilot"
          />
        </a>

        <div className={styles.cards}>
          <Swiper
            slidesPerView="auto"
            spaceBetween={24}
            speed={1000}
            ref={ref}
            initialSlide={0}
            grabCursor
          >
            {REVIEWS.map((r, i) => (
              <SwiperSlide key={i} className={styles.slide}>
                <div className={styles.card}>
                  <Image
                    src={`/reviews/${r.image}`}
                    alt="star"
                    width={320}
                    height={420}
                  />

                  <div className={styles.content}>
                    {!!r.value && (
                      <div className={styles.stars}>
                        {new Array(r.value).fill(null).map((_, i) => (
                          <Image
                            key={`star-${i}`}
                            src={`/star.svg`}
                            alt="star"
                            width={22}
                            height={22}
                          />
                        ))}
                      </div>
                    )}

                    <TextBody size="s">
                      <TextBody size="s" variant="b" weight="bold">
                        {r.name}
                      </TextBody>
                      , {r.location}
                    </TextBody>

                    <TextBody size="s">{r.date}</TextBody>

                    <TextBody size="s" className={styles.review}>
                      {r.review}
                    </TextBody>

                    <TextBody size="s">
                      <TextBody size="s" variant="b" weight="bold">
                        Puppy:
                      </TextBody>{' '}
                      <Link href={r.url} className={styles.link}>
                        {r.puppy}
                      </Link>
                      , {r.breed}
                    </TextBody>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>

          <button
            className={cn(styles.swiperButton, styles.swiperPrev)}
            onClick={goPrev}
          />

          <button
            className={cn(styles.swiperButton, styles.swiperNext)}
            onClick={goNext}
          />
        </div>
      </div>

      <div className={styles.button}>
        <Button
          title="Read all reviews"
          theme={['gold-solid', 'medium']}
          hRef="/reviews"
        />
      </div>
    </div>
  )
}
